import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Radio } from 'imdui';

const Option = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 24px;
  overflow: hidden;
  cursor: pointer;
  background: var(--surface-container-low);

  ${({ selected }) =>
    selected
      ? `
      &:after {
        display: none;
      }
      cursor: default;
box-shadow: inset 0 0 0 1px var(--accent);
  background: var(--accent-surface-container);
`
      : `

box-shadow: 0 0 0 1px var(--outline-var, #CDCBC9);
`}
`;

export const NewPaymentOption = styled.div<{ selected: boolean }>`
  ${({ selected }) =>
    selected
      ? `

${Option} {
  padding: 24px;

           box-shadow: none; margin: 0; border-radius: 0;
}

border-radius: 8px;
box-shadow: 0 0 0 1px var(--accent);
  overflow: hidden;
`
      : `

`}
`;

export const PaymentOption = ({
  children,
  checked,
  onCheck,
  formChildren,
  style,
}: {
  onCheck?: (v: boolean) => void;
  checked?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  formChildren?: React.ReactNode;
}) => {
  const handleClick = useCallback(() => {
    if (onCheck) {
      onCheck(!checked);
    }
  }, [onCheck, checked]);
  return (
    <Option style={style} onClick={handleClick} selected={!!checked}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Radio selected={!!checked} />
        {children}
      </div>
      {formChildren && (
        <div
          style={{
            display: checked ? 'block' : 'none',
            width: '100%',
            marginTop: '16px',
          }}
        >
          {formChildren}
        </div>
      )}
    </Option>
  );
};
