import React, { useMemo, useState, useContext, useCallback } from 'react';
import { lensPath, set, find } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Field, reduxForm } from 'redux-form';
import {
  ToggleSwitchField,
  ArtistSideEditor,
  ContributorSideEditor,
  PublisherSideEditor,
  ReleaseTracksList,
  useProductPage,
  useProductPageActions,
  ContinueWindow,
} from 'components';
import { TRACKS_DETAILS } from '../../hooks';
import { TrackContext } from 'imdshared/src/Next/Track';
import { BarrierField, BuyOrSubOffer } from '../../../../shared';
import {
  useBundleTracks,
  useCustomerFeature,
  useEntryProvider,
  useUpdateEntity,
} from 'imddata';
import { useSelector } from 'react-redux';
import { ToggleSwitch } from 'imdui';
import { VolumeTab } from 'components/organisms/ReleaseTracksList';
import { useProductPriceFormatted, AlertBox } from 'imdshared';

const hasComposer = find((tags) => tags.role === 'composer');
const hasLyricist = find((tags) => tags.role === 'lyricist');

const validateArtists = (artists) => {
  if (!artists || !artists.length) {
    return 'required';
  }

  return null;
};

const emptyRequired = [];

const TrackCidField = () => {
  const id = useContext(TrackContext);
  const track = useSelector((state) => state.entities.tracks.entities[id]);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   return () => {
  //     dispatch(change(form, `track-${id}.addToContentId`, null));
  //   };
  // }, []);

  if (!track) return null;
  const { isInContentId, targets } = track;
  return (
    <div
      style={{ visibility: isInContentId ? 'hidden' : 'visible' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {targets?.addToContentId?.conditions?.contentIdDeliveryAllowed.isMet ===
        false ? (
        <ToggleSwitch disabled={true} />
      ) : (
        <Field
          testId="TrackContentIdSwitch"
          disabled={isInContentId}
          name={`track-${id}.addToContentId`}
          component={ToggleSwitchField}
        />
      )}
    </div>
  );
};

const validate =
  (deliveryBundleTracks) =>
    (
      values,
      {
        trackId,
        targets = {
          addToContentId: {
            conditions: { audioMinimumDuration: { isMet: true } },
          },
        },
        requiredFields = emptyRequired,
      }
    ) => {
      const {
        addToContentId: {
          conditions: { audioMinimumDuration },
        },
      } = targets;
      const errors = {};

      const inCid =
        trackId &&
        !!deliveryBundleTracks?.find(
          (dbt) => dbt.trackId === trackId && dbt.addToContentId
        );
      // eslint-disable-next-line  no-restricted-syntax
      for (const field of requiredFields) {
        if (
          ((Array.isArray(values[field]) && !values[field].length) ||
            !values[field]) &&
          field !== 'title'
        ) {
          errors[field] = 'required';
        }
      }

      errors.contributors = null; // unset as it will be validated differently

      const hasNoTitle = values.names?.find((name) => !name.title);

      if (hasNoTitle) {
        // eslint ignore:line
        errors.names = values.names?.reduce((acc, name, index) => {
          if (!name.title) {
            return set(lensPath([index]), { title: 'required' }, acc);
          }
          return acc;
        }, []);
      }

      errors.artists = validateArtists(values.artists);

      if (
        hasLyricist(values.contributors || []) &&
        values.hasTraditionalLyricist
      ) {
        errors.contributors = 'no-lyricist-for-traditional-lyricist';
      }
      if (
        hasComposer(values.contributors || []) &&
        values.hasTraditionalComposer
      ) {
        errors.contributors = 'no-composer-for-traditional-composer';
      }
      if (
        !hasLyricist(values.contributors || []) &&
        !values.isInstrumental &&
        !values.hasTraditionalLyricist
      ) {
        errors.contributors = 'add-lyricist';
      }
      if (
        !hasComposer(values.contributors || []) &&
        !values.hasTraditionalComposer
      ) {
        errors.contributors = 'add-composer';
      }
      if (values.contributors?.length <= 0) {
        errors.contributors = 'required';
      }

      if (!values.hasNoPublishers) {
        if (!values.publishers || !values.publishers.length) {
          errors.publishers = 'required';
        }
      } else {
        errors.publishers = null;
      }

      if (!values.uploadStatus || values.uploadStatus === 'none') {
        errors.uploadStatus = 'required';
      }
      if (values.uploadStatus && values.uploadStatus === 'failed') {
        errors.uploadStatus = 'audio-processing-failed';
      }

      if (inCid) {
        if (values.isCoverVersion) {
          errors.isCoverVersion = 'not-eligible-for-cid';
        }
        if (values.hasTraditionalLyricist || values.hasTraditionalComposer) {
          errors.contributors = 'not-eligible-for-cid';
        }

        if (values.artists && values.artists.find((a) => a.role === 'remixer')) {
          errors.artists = 'not-eligible-for-cid';
        }

        if (
          values.uploadStatus &&
          values.uploadStatus === 'finished' &&
          // @ts-ignore
          !audioMinimumDuration.isMet
        ) {
          errors.uploadStatus = 'track-minimum-duration-not-met-cid';
        }
      }

      return errors;
    };

const warnArtists = (values) => {
  if (!values.name) {
    return {
      name: 'translate',
    };
  }
  return {};
};

const warnPublishers = (values) => {
  if (!values.name) {
    return {
      name: 'required',
    };
  }
  return {};
};

const warnContributors = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'translate';
  }
  if (!values.lastName) {
    errors.lastName = 'translate';
  }
  return errors;
};

const VolumeTabOrderable = ({ disabled, value, onDecide, onClick, testId }) => {
  const [decideBuy, setDecideBuy] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const multipleVolumesFeature = useCustomerFeature('multiple-volume');
  const price = useProductPriceFormatted('volume');
  const { t } = useTranslation();
  return (
    <>
      <ContinueWindow
        isOpen={openWindow}
        disabled={!decideBuy}
        onCancel={() => {
          setOpenWindow(false);
        }}
        onContinue={(e) => {
          onDecide();
          onClick(e);
        }}
        title={t('add-volume')}
      >
        <BuyOrSubOffer
          subscribeContext={{ analytics: { detail: 'volume' } }}
          price={price}
          title={t('unlock-multiple-volumes')}
          description={t('unlock-multiple-volumes-desc')}
          onDecideSubscribe={() => {
            setOpenWindow(false);
          }}
          onDecideBuy={setDecideBuy}
          decideBuy={decideBuy}
        />
      </ContinueWindow>
      <VolumeTab
        disabled={disabled}
        testId={testId}
        onClick={(e) => {
          if (multipleVolumesFeature || value) {
            onClick(e);
          } else {
            setOpenWindow(true);
          }
        }}
      />
    </>
  );
};

const AddToContentIdForm = reduxForm({
  destroyOnUnmount: false,
  form: 'AddToContentIdForm',
})(({ children }) => children);

const allowedNumberOfTracksPerVolumeStub = { isMet: true };
const trackIdRegex = /track-(.*)/;

const TracksDetails = ({
  validateTrack,
  bundleId,
  releaseId,
  requiredFields,
}) => {
  const {
    state: { steps, data: { acceptedCidTerms, acceptedAiTerms } = {} },
  } = useProductPage();
  const { changeProduct } = useProductPageActions();

  const trackStepId = steps.findIndex((step) => step.id === TRACKS_DETAILS);
  const nextStepVisited = steps[1 + trackStepId]?.visited;
  const allowedNumberOfTracksPerVolume = useSelector(
    (state) =>
      state.entities.releases.entities[releaseId]?.targets.hasAllMetadata
        .conditions.allowedNumberOfTracksPerVolume ||
      allowedNumberOfTracksPerVolumeStub
  );

  const allowedNumberOfVolumes = useSelector(
    (state) =>
      state.entities.releases.entities[releaseId]?.targets.hasAllMetadata
        .conditions.allowedNumberOfVolumes || allowedNumberOfTracksPerVolumeStub
  );

  const { t } = useTranslation();
  const deliveryBundleTracks = useBundleTracks({ id: bundleId });
  const bundle = useEntryProvider({ entity: 'deliveryBundles', id: bundleId });
  const { updateEntry } = useUpdateEntity({
    entity: 'deliveryBundles',
    id: bundleId,
  });
  const initialValues = useMemo(
    () =>
      deliveryBundleTracks.reduce(
        (acc, value) => ({
          ...acc,
          [`track-${value.trackId}`]: {
            trackId: value.trackId,
            addToContentId: value.addToContentId || 0,
            playlistPitchingStatus: value.playlistPitchingStatus,
          },
        }),
        {}
      ),
    [!!deliveryBundleTracks]
  );
  const handleCidUpdate = useCallback((values) => {
    const payload = Object.keys(values).map((key) => {
      const [, trackId] = trackIdRegex.exec(key);
      return {
        trackId,
        addToContentId: values[key].addToContentId || false,
        playlistPitchingStatus: values[key].playlistPitchingStatus,
      };
    });
    updateEntry({
      data: { tracks: payload },
      query: { with: 'tracks' },
      id: bundleId,
    });
  }, []);
  const hasCid = bundle.enableYouTubeContentId;
  const Tab = useCallback(
    (props) => (
      <VolumeTabOrderable
        {...props}
        onDecide={() => {
          updateEntry({
            data: {
              allowPaymentForAdditionalVolumes: true,
            },
          });
        }}
        value={bundle.allowPaymentForAdditionalVolumes}
      />
    ),
    [bundle.allowPaymentForAdditionalVolumes]
  );
  const validateForm = useMemo(
    () => validate(deliveryBundleTracks),
    [deliveryBundleTracks]
  );
  if (deliveryBundleTracks === undefined) return null;
  return (
    <>
      <Helmet>
        <title>{t('page-title-music-distribution-tracks')}</title>
      </Helmet>

      {!allowedNumberOfTracksPerVolume.isMet && (
        <AlertBox
          style={{ marginBottom: 16 }}
          type={AlertBox.Type.error}
          text={t('some-volumes-exceed-volume-limit', { limit: 40 })}
        />
      )}
      {!allowedNumberOfVolumes.isMet && (
        <AlertBox
          style={{ marginBottom: 16 }}
          type={AlertBox.Type.error}
          text={t('release-exceeds-volume-amount', { limit: 40 })}
        />
      )}
      <AddToContentIdForm
        form={'AddToContentIdForm-' + bundleId}
        initialValues={initialValues}
        onChange={handleCidUpdate}
      >
        <ReleaseTracksList
          hasCidToggle={hasCid}
          trackChildren={
            hasCid ? (
              <TrackCidField form={'AddToContentIdForm-' + bundleId} />
            ) : null
          }
          requiredFields={requiredFields}
          hideReleaseName={true}
          disabledCreate={!allowedNumberOfTracksPerVolume.isMet}
          additionalValidation={validateForm}
          renderAddItem={Tab}
          isValid={validateTrack}
          releaseId={releaseId}
          bundleId={bundleId}
        />
        <BarrierField
          testId="ConfirmAiTracks"
          title={t('ai-dmd-barrier-title')}
          description={t('ai-dmd-barrier-description')}
          label={t('ai-dmd-barrier-label', {
            count: deliveryBundleTracks.length,
          })}
          fieldDescription={t('ai-dmd-barrier-label-description', {
            count: deliveryBundleTracks.length,
          })}
          meta={{
            error:
              nextStepVisited && !acceptedAiTerms ? 'field-required' : false,
          }}
          input={{
            onChange: () => {
              changeProduct({ acceptedAiTerms: !acceptedAiTerms });
            },
            value: acceptedAiTerms,
          }}
        />
        {hasCid && (
          <>
            <BarrierField
              testId="ConfirmCidTracks"
              title={t('make-sure-tracks-eligible')}
              description={t('cid-eligibility-list')}
              label={t('confirm-original-tracks-for-cid', {
                count: deliveryBundleTracks.length,
              })}
              fieldDescription={t('right-to-reject-order', {
                count: deliveryBundleTracks.length,
              })}
              meta={{
                error:
                  nextStepVisited && !acceptedCidTerms
                    ? 'field-required'
                    : false,
              }}
              input={{
                onChange: () => {
                  changeProduct({ acceptedCidTerms: !acceptedCidTerms });
                },
                value: acceptedCidTerms,
              }}
            />
          </>
        )}
      </AddToContentIdForm>

      <ContributorSideEditor isAdaptive={false} warn={warnContributors} />

      <ArtistSideEditor isAdaptive={false} warn={warnArtists} />

      <PublisherSideEditor isAdaptive={false} warn={warnPublishers} />
    </>
  );
};

export default TracksDetails;
