import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'ramda';
import type { ConfigProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import type { EntityModels } from 'imddata';
import {
  useDeleteEntity,
  useCreateEntity,
  useUpdateEntity,
  useEntry,
} from 'imddata';
import isEmail from 'validator/lib/isEmail';
import { FormToolbar, Centered, LoadingIndicator, Button } from 'imdui';
import {
  NewInputField,
  CountrySelectorField,
  DatePickerInputField,
} from '../../fields';
import { ColumnsWrapper } from './styles';
import { ResendConfirmation } from './components';
import { ValidationErrorsIBAN, validateIBAN } from 'ibantools';
import type { Moment } from 'moment';
import moment from 'moment';

type PaypalFields = {
  email: string;
};

type AddressFields = {
  name: string;
  dateOfBirth: string;
  countryId: number;
  address: string;
  address2: string;
  city: string;
  zip: string;
};

type BankData = {
  iban: string;
  swift: string;
  bankName: string;
  bankCity: string;
  bankZip: string;
  bankCountryId: number;
  bankAddress: string;
  bankAddress2: string;
};

type FormProps = ConfigProps & {
  actions: React.ReactChild;
  disabled?: boolean;
  onSubmit: (d: any, dispatch: any, s: { form: string }) => void;
};

function validateAddressFields<T extends AddressFields>(fields: T) {
  const errors: Partial<Record<keyof T, string>> = {};

  if (!fields.name) {
    errors.name = 'required';
  }

  if (!fields.dateOfBirth) {
    errors.dateOfBirth = 'required';
  }
  if (!fields.countryId) {
    errors.countryId = 'required';
  }
  if (!fields.address) {
    errors.address = 'required';
  }
  if (!fields.city) {
    errors.city = 'required';
  }
  if (!fields.zip) {
    errors.zip = 'required';
  } else if (fields.zip.length > 10) {
    errors.zip = 'zip-should-be-greater-than-10';
  }

  return errors;
}
function validatePaypalFields<T extends PaypalFields>(fields: T) {
  const errors: Partial<Record<keyof T, string>> = {};
  if (!fields.email) {
    errors.email = 'required';
  } else if (!isEmail(fields.email)) {
    errors.email = 'invalid-email-address';
  }
  return errors;
}

function validateBankFields<T extends BankData>(fields: T) {
  const errors: Partial<Record<keyof BankData, string>> = {};
  if (!fields.iban) {
    errors.iban = 'required';
  } else {
    const { valid, errorCodes } = validateIBAN(fields.iban);
    if (!valid && errorCodes.length) {
      const [firstErr] = errorCodes;
      switch (firstErr) {
        case ValidationErrorsIBAN.NoIBANCountry:
          errors.iban = 'iban-missing-country-code';
          break;
        default:
          console.log(errorCodes);
          errors.iban = 'invalid-iban';
      }
    }
  }

  if (!fields.swift) {
    errors.swift = 'required';
    // } else {
    //   if (!isValidBIC(fields.swift)) {
    //     errors.swift = 'invalid-swift';
    //   }
  }

  return errors;
}

const AddressFields = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        name="name"
        type="text"
        disabled={disabled}
        label={t('account-holder-name')}
        acceptValidation={false}
        component={NewInputField}
      />

      <Field
        name="dateOfBirth"
        type="text"
        disabled={disabled}
        label={t('date-of-birth')}
        isOutsideRange={(day: Moment) => day.isAfter(moment())}
        component={DatePickerInputField}
      />

      <Field
        name="countryId"
        type="text"
        disabled={disabled}
        testId="country"
        floatingLabelText={t('account-holder-country')}
        acceptValidation={false}
        style={{ marginBottom: 24 }}
        component={CountrySelectorField}
      />

      <Field
        name="address"
        type="text"
        disabled={disabled}
        label={t('account-holder-address')}
        acceptValidation={false}
        component={NewInputField}
      />

      <Field
        name="address2"
        type="text"
        disabled={disabled}
        label={t('account-holder-address-line-2')}
        acceptValidation={false}
        component={NewInputField}
      />

      <Field
        name="city"
        disabled={disabled}
        type="text"
        label={t('account-holder-city')}
        acceptValidation={false}
        component={NewInputField}
      />

      <Field
        name="zip"
        type="text"
        disabled={disabled}
        label={t('account-holder-zip')}
        acceptValidation={false}
        component={NewInputField}
      />
    </>
  );
};

const PaypalForm = reduxForm<PaypalFields & AddressFields, FormProps>({
  validate: (fields) => {
    return {
      ...validateAddressFields(fields),
      ...validatePaypalFields(fields),
    };
  },
})(({ onSubmit, disabled, actions, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnsWrapper>
        <section>
          <Field
            name="email"
            type="text"
            disabled={disabled}
            label={t('email')}
            acceptValidation={false}
            component={NewInputField}
            style={{ marginBottom: 24 }}
          />
        </section>

        <section>
          <AddressFields disabled={disabled} />
        </section>
      </ColumnsWrapper>

      <FormToolbar rightChildren={actions} />
    </form>
  );
});

const BankForm = reduxForm<BankData & AddressFields, FormProps>({
  validate: (fields) => {
    return {
      ...validateAddressFields(fields),
      ...validateBankFields(fields),
    };
  },
})(({ actions, disabled, onSubmit, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnsWrapper>
        <section>
          <AddressFields disabled={disabled} />

          <Field
            name="iban"
            type="text"
            disabled={disabled}
            label={t('iban')}
            acceptValidation={false}
            component={NewInputField}
          />

          <Field
            name="swift"
            disabled={disabled}
            type="text"
            label={t('swift')}
            acceptValidation={false}
            component={NewInputField}
          />
        </section>

        <section>
          <Field
            name="bankName"
            type="text"
            disabled={disabled}
            label={t('payout-address-name')}
            acceptValidation={false}
            component={NewInputField}
          />

          <Field
            name="bankCountryId"
            type="text"
            disabled={disabled}
            floatingLabelText={t('payout-address-country')}
            acceptValidation={false}
            style={{ marginBottom: 24 }}
            component={CountrySelectorField}
          />

          <Field
            name="bankAddress"
            type="text"
            disabled={disabled}
            floatingLabelText={t('payout-address-line-1')}
            placeholder={t('address-line-1-placeholder')}
            acceptValidation={false}
            component={NewInputField}
          />

          <Field
            name="bankAddress2"
            type="text"
            disabled={disabled}
            floatingLabelText={t('payout-address-line-2')}
            placeholder={t('address-line-2-placeholder')}
            acceptValidation={false}
            component={NewInputField}
          />

          <Field
            name="bankCity"
            type="text"
            disabled={disabled}
            floatingLabelText={t('payout-address-city')}
            acceptValidation={false}
            component={NewInputField}
          />

          <Field
            name="bankZip"
            type="text"
            disabled={disabled}
            floatingLabelText={t('payout-address-zip')}
            acceptValidation={false}
            component={NewInputField}
          />
        </section>
      </ColumnsWrapper>

      <FormToolbar rightChildren={actions} />
    </form>
  );
});

const PayoutAddressForm = ({
  payoutMethodId,
  ...props
}: { payoutMethodId: EntityModels.PayoutMethodId } & FormProps) => {
  switch (payoutMethodId) {
    case 'paypal':
      return <PaypalForm {...props} />;
    case 'bank':
      return <BankForm {...props} />;
    default:
      return payoutMethodId || 'undefined-payment-method';
  }
};

export const EditPayoutAddressForm = ({
  id,
  canUpdate = false,
  disabled,
  onDelete,
}: {
  id: number | string;
  canUpdate?: boolean;
  onDelete?: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const [isConfirmationRequested, setConfirmationRequested] = useState(false);

  const {
    entry,
    request: { loaded },
  } = useEntry<EntityModels.PayoutAddress>({
    entity: 'payoutAddresses',
    id,
  });
  const {
    updateEntry,
    request: { updating, updated },
  } = useUpdateEntity({
    entity: 'payoutAddresses',
    id,
  });
  const {
    deleteEntry,
    request: { deleting, deleted },
  } = useDeleteEntity({
    entity: 'payoutAddresses',
    id,
  });

  useEffect(() => {
    if (deleted && onDelete) {
      onDelete();
    }
  }, [deleted]);

  const onSubmit = useCallback(
    canUpdate
      ? (values) => {
          updateEntry({
            data: values,
          });
        }
      : () => null,
    [canUpdate]
  );

  if (!loaded || !entry) {
    return (
      <Centered>
        <LoadingIndicator size="large" />
      </Centered>
    );
  }

  const { wasConfirmed, confirmationSent, wasUsed } = entry;

  const actions = (
    <>
      <Button
        onClick={() => deleteEntry()}
        text={t('remove')}
        showLoading={deleting}
      />

      {canUpdate && (
        <Button
          type="submit"
          primary={true}
          text={t('update')}
          showLoading={updating}
        />
      )}
    </>
  );

  return (
    <div>
      <ResendConfirmation
        wasConfirmed={wasConfirmed}
        wasUsed={wasUsed}
        confirmationSent={confirmationSent}
        isConfirmationRequested={isConfirmationRequested}
        setConfirmationRequested={setConfirmationRequested}
        updated={updated}
        updating={updating}
        updateEntry={updateEntry}
      />

      <PayoutAddressForm
        onSubmit={onSubmit}
        form={`editPayout-${entry.payoutMethodId}-${entry.id}`}
        payoutMethodId={entry.payoutMethodId}
        disabled={disabled}
        initialValues={
          entry && entry.payoutMethodId === 'paypal'
            ? {
                dateOfBirth: moment(entry.dateOfBirth),
                countryId: entry.countryId,
                name: entry.name,
                email: entry.email,
                address: entry.address,
                address2: entry.address2,
                city: entry.city,
                zip: entry.zip,
              }
            : entry
              ? {
                  ...entry,
                  dateOfBirth: moment(entry.dateOfBirth),
                }
              : {}
        }
        actions={actions}
      />
    </div>
  );
};

export const AddPayoutAddressForm = ({
  onCreate,
  payoutMethodId,
}: {
  payoutMethodId: EntityModels.PayoutMethodId;
  onCreate?: (id: number | string | null) => void;
}) => {
  const { t } = useTranslation();

  const {
    createEntry,
    createdId,
    request: { creating },
  } = useCreateEntity({
    entity: 'payoutAddresses',
  });

  useEffect(() => {
    if (onCreate && createdId) {
      onCreate(createdId);
    }
  }, [createdId]);

  const onSubmit = useCallback(
    ({ dateOfBirth, ...values }, dispatch, { form }) =>
      new Promise((resolve, reject) => {
        createEntry(
          {
            formId: form,
            data: {
              ...map((value) => (value === null ? '' : value), values),
              dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
              payoutMethodId,
            },
          },
          { resolve, reject }
        );
      }),
    [payoutMethodId]
  );

  const actions = (
    <Button
      type="submit"
      primary={true}
      text={t('create')}
      showLoading={creating}
    />
  );

  return (
    <PayoutAddressForm
      form={`new-${payoutMethodId}`}
      payoutMethodId={payoutMethodId}
      onSubmit={onSubmit}
      actions={actions}
    />
  );
};
