// @flow
import React, { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router-dom';
import { useRouteMatch, Link, Route, Switch, Redirect } from 'react-router-dom';
import { PageNavigation, SubscriptionUpsellContext } from 'imdshared';
import {
  ErrorBoundaryRoute,
  PageTemplate,
  ApplicationSettingsContext,
  ActionCard,
} from 'components';
import {
  Statements,
  Tracks,
  Releases,
  Balance,
  PayoutAddresses,
} from './screens';
import styled from '@emotion/styled';
import {
  ActionGrid,
  Window,
  Button,
  Caption,
  Card,
  Content,
  SubscriptionFeatureGate,
} from 'imdui';
import {
  useCurrentCustomerSubscription,
  useCustomerPricePlan,
  useFetchEntity,
  useSalesStatements,
} from 'imddata';
import { StatementRangeSelector } from './shared';
import { DownloadProvider } from 'imddata/providers';
import { css } from '@emotion/react';
import { BodyM } from '@imus/services-ui/src/Text';

const windowContentStyle = css`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

const windowButtonStyle = css`
  width: 100%;
  margin: 16px 0 0 auto;
`;

const downloadButtonStyle = css`
  width: 100%;
`;
const DownloadButtons = styled.div`
  margin-top: 32px;
  display: grid;
  gap: 16px;
`;
const routes = [
  { to: 'statements', text: 'statements' },
  { to: 'balance', text: 'balance' },
  { to: 'billing', text: 'billing' },
  { to: 'tracks', text: 'tracks' },
  { to: 'releases', text: 'releases' },
];

const TotalRevenueCard = styled(Card)`
  grid-column: 1 / -1;
  justify-items: end;
  gap: 24px;
  margin-bottom: 24px;
  padding: 24px;
  align-items: center;
`;

type TotalValue = { count: number; revenue: number };

type Totals = {
  trackDownloads: TotalValue;
  trackStreams: TotalValue;
  releaseDownloads: TotalValue;
  total: TotalValue;
  monetizations: TotalValue;
};

const Revenue: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();

  const [statementRange, setStatementRange] = useState<[string, string]>();
  const [noticeOpened, setNoticeOpened] = useState(false);
  const [downloadRequested, setDownloadRequested] = useState(false);
  const { entry: customerPricePlan } = useCustomerPricePlan();

  const subscription = useCurrentCustomerSubscription();

  const {
    request: { loaded, loading },
    entries,
  } = useSalesStatements();

  const data = useMemo(
    () => ({
      statements: statementRange,
    }),
    [statementRange]
  );

  const availableDataKeys = useMemo<
    Array<{ value: keyof Totals; label: string }>
  >(
    () => [
      { value: 'total', label: t('total') },
      {
        value: 'trackStreams',
        label: t('track-streams'),
      },
      {
        value: 'releaseDownloads',
        label: t('release-downloads'),
      },
      {
        value: 'trackDownloads',
        label: t('track-downloads'),
      },
      {
        value: 'monetizations',
        label: t('monetizations'),
      },
    ],
    [t]
  );
  const totalsAllTime = useMemo<Totals>(() => {
    const initial: Totals = {
      total: { revenue: 0, count: 0 },
      trackStreams: { revenue: 0, count: 0 },
      releaseDownloads: { revenue: 0, count: 0 },
      trackDownloads: { revenue: 0, count: 0 },
      monetizations: { revenue: 0, count: 0 },
    };

    return entries?.length
      ? entries.reduce<Totals>((acc, e) => {
        return availableDataKeys.reduce(
          (innerAcc, { value }) => ({
            ...innerAcc,
            [value]: {
              count: innerAcc[value].count + Number(e.summary[value]),
              revenue:
                innerAcc[value].revenue +
                Number(e.summary[`${value}RevenuePrecise`]),
            },
          }),
          acc
        );
      }, initial)
      : initial;
  }, [entries]);

  const salesCommission =
    subscription?.subscription?.salesCommission !== undefined
      ? subscription?.subscription?.salesCommission
      : customerPricePlan?.salesCommission;

  const currency = entries?.[0]?.summary.totalRevenueCurrency || 'EUR';
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const empty = loaded && !entries?.length;
  const revenueFormatterWithCurrency = new Intl.NumberFormat(
    numberFormatLocale,
    {
      maximumFractionDigits: 2,
      style: 'currency',
      currency,
    }
  );

  const { open: openUpsell } = useContext(SubscriptionUpsellContext);
  const statementsMatch = useRouteMatch(`${match.path}/statements`);

  const {
    request: { entryCount: legacyBundlesCount },
  } = useFetchEntity({
    entity: 'deliveryBundles',
    queryHash: 'testquery',
    query: {
      'filter.pricePlans': 'starter,regular,rockstar',
      'filter.status': 4,
      perPage: 1,
    },
  });

  return (
    <PageTemplate title={t('revenue')}>
      <PageNavigation match={match} routes={routes} />

      {statementsMatch && (
        <>
          {!!salesCommission && salesCommission !== 0 && (
            <Card style={{ marginBottom: '16px', padding: '24px' }}>
              <SubscriptionFeatureGate
                features={t('sales-statements-upsell-features', {
                  defaultValue: 'Commission rate',
                }).split('|')}
                title={t('sales-statements-upsell-title')}
                action={t('upgrade')}
                onActionClick={() => {
                  openUpsell({ analytics: { detail: 'rev-splits' } });
                }}
              />
            </Card>
          )}
          <TotalRevenueCard>
            <ActionGrid>
              <div>
                <Content>{t('commission-rate', { salesCommission })}</Content>
                <Caption secondary>
                  {t('commission-rate-delay-message')}
                </Caption>
              </div>
              <div />
              <>
                <div>
                  <Content>
                    {t('total-revenue')}:&nbsp;
                    {revenueFormatterWithCurrency.format(
                      totalsAllTime.total.revenue
                    )}
                  </Content>
                  <Caption secondary>{t('earnings-delay-message')}</Caption>
                </div>
                <div>
                  <Button
                    size="small"
                    disabled={empty || !loaded}
                    text={t('statements-download-label')}
                    onClick={() => setNoticeOpened(true)}
                  />
                </div>
              </>
            </ActionGrid>
          </TotalRevenueCard>
        </>
      )}
      {legacyBundlesCount > 0 && salesCommission !== 0 && (
        <ActionCard
          style={{ marginBottom: '24px' }}
          title={t('how-commission-rate-applies')}
          caption={t('legacy-releases-commission-rate-application-text')}
        />
      )}
      <Switch>
        <ErrorBoundaryRoute
          path={`${match.path}/statements`}
          component={Statements}
        />

        <ErrorBoundaryRoute
          path={`${match.path}/releases`}
          component={Releases}
        />
        <ErrorBoundaryRoute
          path={`${match.path}/balance`}
          component={Balance}
        />

        <ErrorBoundaryRoute
          path={`${match.path}/billing`}
          component={PayoutAddresses}
        />
        <ErrorBoundaryRoute path={`${match.path}/tracks`} component={Tracks} />

        <Route
          render={() => {
            return <Redirect to={`${match.url}/statements`} />;
          }}
        />
      </Switch>

      <Window
        title={t('statements-download-label')}
        isOpen={noticeOpened}
        close={() => {
          setNoticeOpened(false);
          setDownloadRequested(false);
        }}
      >
        <div style={{ padding: '0 24px 24px 24px' }}>
          <StatementRangeSelector onChange={setStatementRange} />

          <DownloadButtons>
            <BodyM secondary={true}>{t('download')}:</BodyM>
            <DownloadProvider
              preventDownloadDialog={true}
              data={data}
              downloadType="salesReleases"
            >
              {({ onDownload, requestStatus: { loading: loadingSales } }) => (
                <Button
                  css={downloadButtonStyle}
                  text={t('download-sales-releases')}
                  disabled={loading}
                  size="large"
                  showLoading={loadingSales}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onDownload();
                    setDownloadRequested(true);
                  }}
                />
              )}
            </DownloadProvider>

            <DownloadProvider
              preventDownloadDialog={true}
              data={data}
              downloadType="sales"
            >
              {({ onDownload, requestStatus: { loading: loadingSales } }) => (
                <Button
                  css={downloadButtonStyle}
                  size="large"
                  primary={true}
                  text={t('download-sales')}
                  disabled={loading}
                  showLoading={loadingSales}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onDownload();
                    setDownloadRequested(true);
                  }}
                />
              )}
            </DownloadProvider>
          </DownloadButtons>
        </div>

        {downloadRequested && (
          <div css={windowContentStyle}>
            <div>
              <Trans i18nKey="sales-download-notice">
                Your download is being processed. Visit{' '}
                <Link to="/downloads">Downloads</Link>.
              </Trans>
            </div>

            <Button
              css={windowButtonStyle}
              text={t('ok')}
              onClick={() => {
                setDownloadRequested(false);
                setNoticeOpened(false);
              }}
            />
          </div>
        )}
      </Window>
    </PageTemplate>
  );
};

export default Revenue;
