/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
//
import React, {
  memo,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { fabric } from 'fabric';
import WebFont from 'webfontloader';
import { Trans, useTranslation } from 'react-i18next';
import {
  NewInput,
  ComponentIcons,
  Button,
  ButtonText,
  IconButton,
  Content,
  BodySmall,
  HelpWindowContext,
} from 'imdui';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import sanitizeText from './sanitizeText';
import EdtitorMenu from './components/EditorMenu';

import type { UnsplashImage } from './components/BackgroundSelector';
import BackgroundSelector from './components/BackgroundSelector';
import { useTheme } from '@emotion/react';

const StyledIconButton = styled(IconButton)`
  background: rgba(0, 0, 0, 0.05);
`;

const Container = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 16px;
`;

const BackgroundSelectorWrapper = styled.div`
  margin-bottom: 16px;
`;

const ImageCreditText = styled(BodySmall)`
  display: block;
  margin: 12px 0 0px 0;
  a {
    color: var(--on-surface);
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 8px;
  ${ButtonText} {
    margin: 0 auto;
    text-align: center;
  }
`;

type Props = {
  dimensionError?: boolean;
  onDrop: DropzoneOptions['onDrop'];
  className?: string;
};

const DropzoneStyled = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: var(--bg-1);
  box-shadow: 0 0 0 1px rgb(236 236 236);
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  &:hover {
    background-color: var(--bg-1);
    box-shadow: 0 0 0 1px rgb(220 220 220);
  }
  &:active {
    background-color: var(--bg-4);
  }
`;
const DropzoneContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  svg {
    margin-bottom: 16px;
  }
`;

const Positioner = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  left: 0;
  position: absolute;
`;

const accept = {
  ['image/*']: ['.png', '.jpg', '.jpeg', '.tiff', '.tif'],
};

const CoverDropzone: React.FC<Props> = ({
  onDrop,
  dimensionError,
  className,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: accept,
  });
  return (
    <DropzoneStyled
      data-test-id="cover-dropzone"
      key="container"
      className={className}
      {...getRootProps()}
    >
      <input data-test-id="cover-dropzone-input" {...getInputProps()} />
      <DropzoneContent>
        <svg
          width="36"
          height="48"
          viewBox="0 0 36 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6268 4.54597C17.3975 3.81801 18.6025 3.81801 19.3732 4.54597L31.3732 15.8793C32.1763 16.6377 32.2125 17.9035 31.454 18.7066C30.6956 19.5096 29.4298 19.5458 28.6268 18.7874L20 10.6399V32C20 33.1046 19.1046 34 18 34C16.8954 34 16 33.1046 16 32V10.6399L7.37325 18.7874C6.57021 19.5458 5.3044 19.5096 4.54597 18.7066C3.78755 17.9035 3.82372 16.6377 4.62675 15.8793L16.6268 4.54597ZM2 42C2 40.8954 2.89543 40 4 40H32C33.1046 40 34 40.8954 34 42C34 43.1046 33.1046 44 32 44H4C2.89543 44 2 43.1046 2 42Z"
            fill="var(--on-surface)"
          />
        </svg>

        <BodySmall secondary style={{ margin: '0 16px' }}>
          {t('drop-cover-here-or-click-to-add')} <br />
        </BodySmall>
        <BodySmall
          secondary
          style={{
            marginTop: '4px',
            color: dimensionError ? theme.state.error : undefined,
          }}
        >
          3000x3000px PNG/JPG/TIFF
        </BodySmall>
      </DropzoneContent>
    </DropzoneStyled>
  );
};

const OUTPUT_SIZE = 3000;

const ARTIST_COORDS = {
  top: 265,
  left: 20,
};
const ALBUM_COORDS = {
  top: 240,
  left: 20,
};

const INITIAL_FONT_FACE = 'Nunito Sans';
const INITIAL_FONT_COLOR = '#fff';

const useArtworkCanvas = ({ size = 320 } = { size: 320 }) => {
  const [fontFamily, setFontFamily] = useState(INITIAL_FONT_FACE);
  const [fontColor, setFontColor] = useState(INITIAL_FONT_COLOR);
  const background = useRef<fabric.Image>();
  const imageObj = useRef<File | UnsplashImage>();
  const previewCanvas = useRef<fabric.Canvas>();
  const artist = useRef<fabric.Textbox>(
    new fabric.Textbox('', {
      // textAlign: 'center',
      // lockScalingY: true,
      // lockScalingX: true,
      // lockUniScaling: false,
      fontFamily,
      fontSize: 24,
      fill: fontColor,
      editable: false,
      width: size - 80,
      ...ARTIST_COORDS,
    })
  );

  const album = useRef<fabric.Textbox>(
    new fabric.Textbox('', {
      // textAlign: 'center',
      fontFamily,
      // lockScalingY: true,
      // lockScalingX: true,
      // lockUniScaling: false,
      fontSize: 28,
      fill: fontColor,
      width: size - 80,
      originY: 'center',
      editable: false,
      ...ALBUM_COORDS,
    })
  );

  // useLayoutEffect(() => {
  //   return () => {
  //     previewCanvas.current?.dispose();
  //   };
  // });
  //
  useEffect(() => {
    if (previewCanvas.current && album.current && artist.current) {
      album.current.set('fill', fontColor);
      artist.current.set('fill', fontColor);
      previewCanvas.current.requestRenderAll();
    }
  }, [fontColor, fontFamily]);

  const resetTextPosition = useCallback(() => {
    if (previewCanvas.current && album.current && artist.current) {
      album.current.set('left', ALBUM_COORDS.left);
      album.current.set('top', ALBUM_COORDS.top);
      album.current.set('angle', 0);
      album.current.set('fontSize', 28);

      artist.current.set('left', ARTIST_COORDS.left);
      artist.current.set('top', ARTIST_COORDS.top);
      artist.current.set('angle', 0);
      artist.current.set('fontSize', 24);
      previewCanvas.current.requestRenderAll();
    }
  }, []);
  const resetAllStyles = useCallback(() => {
    album.current.set('fontFamily', INITIAL_FONT_FACE);
    artist.current.set('fontFamily', INITIAL_FONT_FACE);
    album.current.set('fill', INITIAL_FONT_COLOR);
    artist.current.set('fill', INITIAL_FONT_COLOR);
    resetTextPosition();
  }, []);

  useEffect(() => {
    WebFont.load({
      classes: false,
      timeout: 10000,
      google: {
        families: [`${fontFamily}:400`],
      },
      fontactive: (font: any) => {
        if (artist.current && album.current && previewCanvas.current) {
          artist.current.set('fontFamily', font);
          album.current.set('fontFamily', font);
          previewCanvas.current.requestRenderAll();
        }
      },
    });
  }, [fontFamily]);

  const init = useMemo(
    () => (node: HTMLCanvasElement) => {
      if (previewCanvas.current) {
        previewCanvas.current.dispose();
      }
      previewCanvas.current = new fabric.Canvas(node, {
        backgroundColor: '#000000',
        renderOnAddRemove: false,
        preserveObjectStacking: true,
        uniformScaling: false,
        uniScaleKey: 'ctrlKey',

        height: size,
        width: size,
      });
      previewCanvas.current.add(album.current);
      previewCanvas.current.add(artist.current);

      previewCanvas.current.bringForward(artist.current);
      previewCanvas.current.bringForward(album.current);

      WebFont.load({
        classes: false,
        timeout: 10000,
        google: {
          families: [`${fontFamily}:400`],
        },
        fontactive: (font: any) => {
          if (artist.current && album.current && previewCanvas.current) {
            artist.current.set('fontFamily', font);
            album.current.set('fontFamily', font);
            previewCanvas.current.requestRenderAll();
          }
        },
      });
    },
    []
  );

  const setImage = useCallback((i: fabric.Image, callback?: () => void) => {
    if (!i.width || !i.height || !previewCanvas.current) return;
    const scale = Math.max(size / i.width, size / i.height);
    i.set({
      originX: 'left',
      originY: 'top',
      selectable: true,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      scaleX: scale,
      scaleY: scale,
    });

    previewCanvas.current.discardActiveObject();
    previewCanvas.current.add(i);
    previewCanvas.current.sendToBack(i);

    if (artist.current && album.current) {
      previewCanvas.current.bringForward(artist.current);
      previewCanvas.current.bringForward(album.current);
    }

    previewCanvas.current.renderAll();
    if (callback) {
      callback();
    }
    background.current = i;
  }, []);

  const generateFile = useCallback(async (): Promise<{
    fullsize: Blob | undefined;
    thumbnail: string | undefined;
  }> => {
    if (!previewCanvas.current)
      return {
        fullsize: undefined,
        thumbnail: undefined,
      };
    const loadHqImage = () => {
      return new Promise((resolve) => {
        if (imageObj.current && 'urls' in imageObj.current) {
          if (previewCanvas.current && background.current) {
            // otherwise lowres will be rendered
            previewCanvas.current.remove(background.current);
          }

          fabric.Image.fromURL(
            imageObj.current.urls.raw,
            (img: any) => {
              setImage(img, () => {
                resolve(true);
              });
            },
            {
              crossOrigin: 'Anonymous',
            }
          );
        } else {
          resolve(true);
        }
      });
    };

    await loadHqImage();

    const renderBlob = (): Promise<Blob | undefined> => {
      return new Promise((resolve) => {
        if (previewCanvas.current) {
          const scaleRatio = OUTPUT_SIZE / size;
          const canvas = previewCanvas.current.toCanvasElement(scaleRatio);
          canvas.toBlob(
            (b) => {
              if (b) {
                resolve(b);
              } else {
                resolve(undefined);
              }
            },
            'image/png',
            1
          );
        } else {
          resolve(undefined);
        }
      });
    };

    const file = await renderBlob();
    // Testing
    // const blobUrl = URL.createObjectURL(file);
    // window.location.replace(blobUrl);
    // console.log(file);
    return {
      fullsize: file,
      thumbnail: previewCanvas.current.toDataURL(),
    };
  }, []);
  const attachImage = useCallback((image) => {
    if (!image || !previewCanvas.current) return;
    if (background.current) {
      previewCanvas.current.remove(background.current);
      background.current = undefined;
    }
    imageObj.current = image;

    if (image.urls) {
      fabric.Image.fromURL(image.urls.regular, setImage, {
        crossOrigin: 'Anonymous',
      });
      // generateFile().then(console.log);
    }
    if (image.name) {
      const reader = new FileReader();
      reader.onload = (f) => {
        if (f.target?.result && typeof f.target.result === 'string') {
          fabric.Image.fromURL(f.target.result, setImage, {
            crossOrigin: 'Anonymous',
          });
        }
      };

      reader.readAsDataURL(image);
    }
    previewCanvas.current.requestRenderAll();
  }, []);

  const updateText = useCallback((title: string, displayArtist: string) => {
    album.current?.set('text', sanitizeText(title));
    artist.current?.set('text', sanitizeText(displayArtist));

    previewCanvas.current?.requestRenderAll();
  }, []);

  return {
    init,
    generateFile,
    attachImage,
    updateText,
    fontColor,
    fontFamily,
    setFontFamily,
    setFontColor,
    resetAllStyles,
    resetTextPosition,
  };
};

const BackgroundHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
`;

const BackgroundActions = styled.div`
  display: flex;
  gap: 12px;
`;

const EditorMenuButtons = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
`;

const checkDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const img = new Image();

    img.onload = function () {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(img.src);
    };

    img.onerror = function () {
      reject();
    };

    img.src = url;
  });
};

// TODO:
// - remove form from here and albumPlaceholder
function ArtworkEditor(props: {
  title: string;
  showCoverRequirementText: boolean;
  displayArtist: string;
  children?: React.ReactNode;
  onSelect?: (type: 'custom' | 'gallery') => void;
  onUpload: (d: {
    fullsize: Blob | File | undefined;
    thumbnail: string | undefined;
  }) => void;
}) {
  const { t } = useTranslation();
  const [generating, setGenerating] = useState(false);
  const [selectedImage, setSelectedImage] = useState<UnsplashImage>();
  const [customBackground, setCustomBackground] = useState<File>();

  const {
    init,
    updateText,
    attachImage,
    generateFile,
    fontFamily,
    resetTextPosition,
    resetAllStyles,
    setFontFamily,
    setFontColor,
    fontColor,
  } = useArtworkCanvas();
  const title = customBackground ? '' : props.title;
  const displayArtist = customBackground ? '' : props.displayArtist;
  const [dimensionError, setDimensionError] = useState(false);
  useLayoutEffect(() => {
    attachImage(selectedImage);
  }, [selectedImage]);

  const handleDrop = useCallback(async (files: File[]) => {
    const file = files[0];
    if (file && file.type && file.type.match('image.*')) {
      try {
        const { width, height } = await checkDimensions(file);
        if (width >= 3000 && height >= 3000) {
          setSelectedImage(undefined);
          setCustomBackground(file);
          attachImage(file);
        } else {
          setDimensionError(true);
        }
      } catch {
        props.onUpload({ fullsize: file, thumbnail: undefined });
      }
    }
  }, []);

  useLayoutEffect(() => {
    updateText(title, displayArtist);
  }, [title, displayArtist]);

  const hasImage = selectedImage || customBackground;
  const [backgroundSearch, setBackgroundSearch] = useState('');
  const [enableSearch, setEnableSearch] = useState(false);
  const showHelpWindow = useContext(HelpWindowContext);

  const [enableCustomizations, setEnableCustomizations] = useState(false);

  const handleCustomBackgroundSelect = useCallback((image: UnsplashImage) => {
    setCustomBackground(undefined);
    setSelectedImage(image);
    setEnableSearch(false);
  }, []);

  const callback = useCallback((n) => init(n), []);

  useEffect(() => {
    if (props.onSelect) {
      if (customBackground) {
        props.onSelect('custom');
        return;
      }
      props.onSelect('gallery');
    }
  }, [selectedImage, props.onSelect, customBackground]);

  return (
    <>
      <Container>
        <Positioner
          style={{
            display: hasImage ? 'none' : 'block',
          }}
        >
          <CoverDropzone onDrop={handleDrop} dimensionError={dimensionError} />
        </Positioner>
        <canvas
          key="preview"
          ref={callback}
          style={{
            left: 0,
            right: 0,
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: '4px',
            // visibility: hasImage ? 'visible' : 'hidden',
          }}
        />
      </Container>
      {!customBackground && (
        <BackgroundSelectorWrapper>
          <div data-test-id="template-previews">
            <BackgroundHeading>
              {enableSearch ? (
                <NewInput
                  style={{ margin: '0' }}
                  value={backgroundSearch}
                  placeholder={t('search-gallery')}
                  onChange={(e) => {
                    setBackgroundSearch(e.target.value);
                  }}
                />
              ) : (
                <Content>{t('background-image')}</Content>
              )}
              <BackgroundActions>
                {!enableSearch && (
                  <StyledIconButton
                    onClick={() => {
                      setEnableCustomizations(!enableCustomizations);
                    }}
                    disabled={!selectedImage}
                  >
                    {enableCustomizations ? (
                      <ComponentIcons.Close />
                    ) : (
                      <ComponentIcons.StyleEditor />
                    )}
                  </StyledIconButton>
                )}
                {!enableCustomizations && (
                  <StyledIconButton
                    onClick={() => {
                      setEnableSearch(!enableSearch);
                    }}
                  >
                    {!enableSearch ? (
                      <ComponentIcons.Search />
                    ) : (
                      <ComponentIcons.Close />
                    )}
                  </StyledIconButton>
                )}
              </BackgroundActions>
            </BackgroundHeading>

            {enableCustomizations && (
              <EditorMenuButtons>
                <EdtitorMenu
                  textFontFamily={fontFamily}
                  onColorChange={setFontColor}
                  onFontFamilyChange={setFontFamily}
                  textColor={fontColor}
                />
                <Button
                  text={t('undo-all-changes')}
                  iconProps={{ style: { marginLeft: 'auto' } }}
                  onClick={resetAllStyles}
                  icon={ComponentIcons.Return}
                />
              </EditorMenuButtons>
            )}

            {!enableCustomizations && (
              <BackgroundSelector
                enableSearch={enableSearch}
                searchString={backgroundSearch}
                onSelectImage={handleCustomBackgroundSelect}
                selectedImage={selectedImage}
                onDrop={handleDrop}
              />
            )}
          </div>
          {props.showCoverRequirementText && (
            <ImageCreditText>
              <Trans
                i18nKey="cover-requirements-notice"
                defaults="Our <0>Release Artwork Guidelines</0> allow us to review image and reject the release if it doesn’t meet our requirements"
                components={[
                  <a
                    key="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      showHelpWindow(
                        t('cover-artwork'),
                        t('cropper-cover-text')
                      );
                    }}
                  />,
                ]}
              />
            </ImageCreditText>
          )}
          {selectedImage && (
            <ImageCreditText>
              <Trans
                i18nKey="unsplash-cover-attribution"
                defaults="Image by <0>{{author}}</0> on <1>{{unsplash}}</1>"
                values={{
                  author: selectedImage.user.name,
                  unsplash: 'Unsplash',
                }}
                components={[
                  <a
                    key="user"
                    href={selectedImage.user.links.html}
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                  <a
                    key="unsplash"
                    href="https://unsplash.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
              />
            </ImageCreditText>
          )}
        </BackgroundSelectorWrapper>
      )}
      {props.children}
      {hasImage && (
        <Buttons>
          <Button
            size="small"
            text={t('clear')}
            onClick={() => {
              resetTextPosition();
              setSelectedImage(undefined);
              setCustomBackground(undefined);
              setEnableSearch(false);
              setEnableCustomizations(false);
            }}
          />
          <Button
            testId={`ReleaseForm-UploadCover-${'enabled'}`}
            size="small"
            disabled={generating}
            showLoading={generating}
            primary={true}
            text={t('save')}
            onClick={async () => {
              setGenerating(true);
              const file = await generateFile();
              props.onUpload(file);
              setGenerating(false);
            }}
          />
        </Buttons>
      )}
    </>
  );
}

export default memo(ArtworkEditor);
