import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerPaymentMethod, EnabledField } from 'components';
import { NewPaymentOption, PaymentOption } from '../PaymentOption';
import type { GatewayProps } from '../types';
import { BodySmall } from 'imdui';

const PaypalGateway: React.FC<GatewayProps> = ({
  onPaymentChange,
  paymentData,
  formData,
}) => {
  const { t } = useTranslation();

  const [storePaymentMethod, setStoreMethod] = useState(
    () => !!formData?.enforceStorePaymentMethod
  );

  return (
    <>
      <NewPaymentOption selected={paymentData.id === 'new-paypal'}>
        <PaymentOption
          checked={paymentData.id === 'new-paypal'}
          onCheck={() =>
            onPaymentChange({
              id: 'new-paypal',
              isReady: true,
              paymentMethod: 'paypal',
              data: {
                paymentMethodId: 'paypal',
                data: {
                  storePaymentMethod,
                },
              },
            })
          }
          formChildren={
            <>
              {!formData?.enforceStorePaymentMethod && (
                <div>
                  <EnabledField
                    // @ts-ignore
                    meta={{}}
                    name="save-method"
                    label={t('save-payment-method')}
                    // @ts-ignore
                    input={{
                      value: storePaymentMethod,
                      onChange: (value) => {
                        setStoreMethod(value);
                      },
                    }}
                  />
                </div>
              )}

              {formData?.enforceStorePaymentMethod && (
                <BodySmall>
                  {t('your-payment-details-will-be-stored-for-future-renewals')}
                </BodySmall>
              )}
            </>
          }
        >
          <CustomerPaymentMethod
            scheme="new-paypal"
            caption={formData?.fee + ' ' + t('fee')}
            description={t('new-paypal')}
            data={{ shopperEmail: '' }}
          />
        </PaymentOption>
      </NewPaymentOption>
    </>
  );
};

export default PaypalGateway;
