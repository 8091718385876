//
import React from 'react';
import styled from '@emotion/styled';
import LegendRow from './LegendRow';

const LegendWrapper = styled.div`
  padding: 24px 0;
  margin: 0 16px;
  box-shadow: inset 0 1px 0 0 var(--outline-var);
`;

const DatePickerLegend = ({ planId, dateRanges }) => {
  return (
    <div>
      <LegendWrapper>
        {dateRanges.legend
          .filter(
            (o) =>
              ['ultraexpressdelivery', 'expressdelivery'].indexOf(o.id) >= 0
          )
          .map((option) => (
            <LegendRow key={option.id} planId={planId} option={option} />
          ))}
      </LegendWrapper>
    </div>
  );
};

export default DatePickerLegend;
