import React from 'react';
import styled from '@emotion/styled';
import { Content, H4, BodyXS } from 'imdui';
import type { EntityModels } from 'imddata';

const RightSection = styled.span`
  align-items: baseline;
  flex-direction: column;
  display: flex;
  ${BodyXS} {
    margin-top: 8px;
  }
`;

const Wrapper = styled.div`
  ${Content} {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
  }
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 1;
  }
`;

type NewPaypalScheme = {
  scheme: 'new-paypal';
  caption?: string;
  description?: string;
  data: EntityModels.CustomerPaymentMethodPaypal['data'];
};

type PaypalScheme = {
  scheme: EntityModels.CustomerPaymentMethodPaypal['paymentMethodId'];
  caption?: string;
  description?: string;
  data: EntityModels.CustomerPaymentMethodPaypal['data'];
};

type CardScheme = {
  scheme: EntityModels.CustomerPaymentMethodCard['paymentMethodId'];
  caption?: string;
  description?: string;
  data: EntityModels.CustomerPaymentMethodCard['data'];
};

type NewCardScheme = {
  scheme: 'new-card';
  caption?: string;
  description?: string;
  data: { name?: string };
};

type Props = NewPaypalScheme | PaypalScheme | CardScheme | NewCardScheme;

// const formatDate = (expiryMonth: number, expiryYear: number) => {
//   return `${expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth}/${expiryYear
//     .toString()
//     .slice(-2)}`;
// };

const supportedCards = [
  'paypal',
  'diners',
  'discover',
  'visa',
  'mir',
  'maestro',
  'mc',
  'amex',
];

export const getBrandIconUrl = (brand = '') => {
  let mappedname = brand?.toLowerCase();
  switch (mappedname) {
    case 'mastercard':
      mappedname = 'mc';
      break;
    case 'diners club':
      mappedname = 'diners';
      break;
    default:
      break;
  }

  if (supportedCards.indexOf(mappedname) >= 0) {
    return `/images/payment-methods/${mappedname}.svg`;
  }
  console.log('Brand Image Not Found: ' + brand);
  return `/images/payment-methods/card.svg`;
};

export const CustomerPaymentMethod = (p: Props) => {
  if (p.scheme === 'new-paypal') {
    return (
      <Wrapper>
        <RightSection>
          <H4>{p.description}</H4>
          {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
        </RightSection>
        <div>
          <img src={getBrandIconUrl('paypal')} alt="paypal" height={32} />
        </div>
      </Wrapper>
    );
  }
  if (p.scheme === 'paypal') {
    return (
      <Wrapper>
        <RightSection>
          <H4>{p.data.shopperEmail}</H4>
          {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
        </RightSection>
        <div>
          <img src={getBrandIconUrl('paypal')} alt="paypal" height={32} />
        </div>
      </Wrapper>
    );
  }
  if (p.scheme === 'card') {
    const { data } = p;
    if (!data) return null;
    const brandUrlSvg = getBrandIconUrl(data?.brand || data?.name);
    return (
      <Wrapper>
        <RightSection>
          <H4>{`**** ${data.lastFour}`}</H4>
          {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
        </RightSection>
        <div>
          <img src={brandUrlSvg} alt={data.name} height={32} />
        </div>
      </Wrapper>
    );
  }
  if (p.scheme === 'new-card') {
    const { data, description } = p;
    const brandUrlSvg = getBrandIconUrl(data?.name);
    return (
      <Wrapper>
        <RightSection>
          <H4>{description}</H4>
          {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
        </RightSection>

        <div>
          {brandUrlSvg === '/images/payment-methods/card.svg' ? (
            <>
              <img
                src="/images/payment-methods/visa.svg"
                alt={data?.name}
                height={20}
                style={{ marginRight: 4 }}
              />
              <img
                src="/images/payment-methods/mc.svg"
                alt={data?.name}
                height={20}
              />
            </>
          ) : (
            <img src={brandUrlSvg} alt={data?.name} height={32} />
          )}
        </div>
      </Wrapper>
    );
  }
  return null;
};
