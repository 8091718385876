import { Configuration, FrontendApi, type Session } from '@ory/client';
import type { ReduxState } from 'imddata';
import { useCreateEntity } from 'imddata';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import config from 'imdconfig';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSignupCodes } from './shared/useSignupCodes';

const ory = new FrontendApi(
  new Configuration({
    basePath: config.kratosUrl,
    baseOptions: {
      withCredentials: true,
    },
  })
);
export const KratosGateway = ({
  children,
  loader,
}: {
  children: any;
  loader: any;
}): any => {
  const { referral, affiliate } = useSignupCodes();
  const [loaded, setLoaded] = useState(false);

  const loggedUserRequested = useSelector(
    (state: ReduxState) => state.auth && state.auth.isRequested
  );
  const hasProfile = useSelector(
    (state: ReduxState) => state.auth && !!state.auth.profile
  );

  const dispatch = useDispatch();

  const { createEntry, request } = useCreateEntity({
    entity: 'registerAction',
  });

  const [session, setSession] = useState<Session>();
  useEffect(() => {
    if (!loaded) {
      ory
        .toSession()
        .then((d) => {
          setSession(d.data);
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(true);
        });
    }
  }, [loaded]);

  useEffect(() => {
    if (!session || !session.active) return;

    if (!loggedUserRequested) {
      dispatch(loadLoggedUser());
    }
  }, [session]);

  useEffect(() => {
    if (loaded && !session?.active) {
      const returnTo = `${process.env.APP_URL}${new URL(window.location.href).pathname}${new URL(window.location.href).search}`;
      const redirect: string = `${process.env.AUTH_APP_URL}/signin?return_to=${returnTo}`;

      window.location.href = redirect;
    }
  }, [loaded, !!session]);

  useEffect(() => {
    if (loggedUserRequested && !hasProfile) {
      createEntry({
        data: {
          affiliatePartnerToken: affiliate,
          referralToken: referral,
        },
      });
    }
  }, [loggedUserRequested, hasProfile, affiliate, referral]);

  useEffect(() => {
    dispatch(loadLoggedUser());
  }, [request.created]);

  if (!loaded || !hasProfile) return loader;

  return children;
};
