import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { useEntryProvider, useCreateEntity, useEntries } from 'imddata';

import type { PaymentMethodData, PaymentData } from './types';
import { PaymentMethodContainer } from './PaymentMethodContainer';
import { PaymentProcessor } from './PaymentProcessor';
import { postPayment } from './postPayment';
import { Button, HelperText } from 'imdui';

const useStorePayment = (methodId: 'card' | 'paypal') => {
  const {
    createEntry: makePayment,
    request,
    createdId,
  } = useCreateEntity({
    entity: 'customerPaymentMethods',
    componentKey: `Store-${methodId}`,
  });

  // TODO: define result as an object return somehow and avoid using createdId field for this
  // @ts-ignore
  const transactionId = createdId?.transaction;
  // TODO: define result as an object return somehow and avoid using createdId field for this
  // @ts-ignore
  const customerPaymentMethodId = createdId?.item;

  const transaction = useEntryProvider<EntityModels.Transaction>({
    entity: 'transactions',
    id: transactionId,
  });

  useEffect(() => {
    if (transaction) {
      const { redirectUrl, redirectMethod, redirectData } = transaction;
      if (redirectUrl) {
        if (redirectMethod.toUpperCase() === 'POST') {
          postPayment(redirectUrl, redirectData);
          return;
        }
        window.location.href = redirectUrl;
      }
      // if (
      //   transaction.status === 'settlement_failed' ||
      //   transaction.status === 'failed'
      // ) {
      //   //TODO: handle failure?
      // }
    }
  }, [transaction, customerPaymentMethodId]);

  const onStore = useCallback(
    (data = {}) => {
      makePayment({
        id: methodId,
        data,
      });
    },
    [makePayment, methodId]
  );

  const transactionStatus = transaction?.status;

  return { onStore, request, customerPaymentMethodId, transactionStatus };
};

export function StoreCardGate({
  paymentMethodId,
  onCancel,
  paymentMethodData,
}: {
  paymentMethodId: 'paypal' | 'card';
  onCancel: () => void;
  paymentMethodData: PaymentMethodData;
}) {
  const { refresh } = useEntries({ entity: 'customerPaymentMethods' });
  const { t } = useTranslation();

  const initData = useMemo<PaymentData>(
    () =>
      paymentMethodId === 'paypal'
        ? {
          id: `new-paypal`,
          paymentMethod: 'paypal',
          isReady: true,
          data: {
            paymentMethodId: 'paypal',
            data: { storePaymentMethod: true },
          },
        }
        : { id: 'new-card', paymentMethod: 'card', isReady: false },
    []
  );

  const [paymentData, setPaymentData] = useState(() => initData);

  const { request, onStore, customerPaymentMethodId, transactionStatus } =
    useStorePayment(paymentMethodId);

  useEffect(() => {
    if (customerPaymentMethodId) {
      refresh();
      onCancel();
    }
  }, [customerPaymentMethodId]);

  const processorPaymentMethodData = useMemo(
    () => ({
      data: {
        ...paymentMethodData.data,
        enforceStorePaymentMethod: true,
      },
    }),
    [paymentMethodData]
  );

  const handleChange = useCallback((pd) => {
    setPaymentData(pd);
  }, []);

  return (
    <>
      <PaymentMethodContainer id={paymentMethodId}>
        <div>
          <PaymentProcessor
            paymentMethodData={processorPaymentMethodData}
            paymentMethod={paymentMethodId}
            paymentData={initData}
            onChange={handleChange}
          />
        </div>
        <HelperText
          errorText={
            transactionStatus === 'authorization_failed'
              ? t('card-authorization-failed')
              : undefined
          }
        />
      </PaymentMethodContainer>
      <>
        <Button
          type="button"
          style={{ marginTop: '32px' }}
          disabled={!paymentData.isReady || request.creating}
          primary={true}
          showLoading={request.creating}
          text={t('store-payment-method', { context: paymentMethodId })}
          onClick={() => {
            onStore(paymentData.data?.data);
          }}
        />
      </>
    </>
  );
}
