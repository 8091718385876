import React, { useContext } from 'react';
import { Content } from 'imdui';
import { ApplicationSettingsContext } from 'imdshared';
import { useTranslation } from 'react-i18next';
import type { EntityModels, Nil } from 'imddata';
import { DetailsRow } from '../../../../shared';
import styled from '@emotion/styled';
import { OverviewGroupLabel } from 'components';

const Details = styled.div`
  display: grid;
  gap: 16px;
`;

const Items = styled.div`
  display: grid;
  gap: 8px;
`;

export default function PaymentOverviewDetails({
  overview,
  paymentMethod,
}: {
  overview: EntityModels.InvoiceOverview;
  paymentMethod: 'card' | 'paypal' | Nil | null;
}) {
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const currency = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

  const paymentMethodData =
    paymentMethod === 'card' || paymentMethod === 'paypal'
      ? overview?.availablePaymentMethods[paymentMethod]
      : null;

  return (
    <Details data-test-id="PaymentGate-to-pay">
      <Items>
        {overview?.groups &&
          Object.values(overview.groups).map((g, idx) => {
            return (
              <DetailsRow key={idx}>
                <Content>
                  <OverviewGroupLabel overviewGroup={g} />
                </Content>
                {g.value ? (
                  <Content>{priceFormatter.format(Number(g.value))}</Content>
                ) : (
                  <Content>-</Content>
                )}
              </DetailsRow>
            );
          })}
      </Items>
      <Items>
        {overview?.payments &&
          overview.payments.map(({ type, name, value }, i) => (
            <DetailsRow key={i}>
              <Content>{t(type, { defaultValue: name })}</Content>

              <Content>{priceFormatter.format(Number(-value))}</Content>
            </DetailsRow>
          ))}

        {overview?.discounts &&
          overview.discounts.map(({ type, name, appliedDiscount }, i) => (
            <DetailsRow key={i}>
              <Content>{t(type, { defaultValue: name })}</Content>
              <Content>
                {priceFormatter.format(Number(-appliedDiscount))}
              </Content>
            </DetailsRow>
          ))}

        <DetailsRow>
          <Content>{t('payment-fee')}</Content>
          {paymentMethod && overview ? (
            <Content>
              {priceFormatter.format(Number(paymentMethodData?.fee || 0))}
            </Content>
          ) : (
            <Content>-</Content>
          )}
        </DetailsRow>

        <DetailsRow>
          <Content>{t('amount-to-pay')}</Content>
          {paymentMethod && overview ? (
            <Content>{priceFormatter.format(Number(overview.toPay))}</Content>
          ) : (
            <Content>{paymentMethod ? t('calculating') : '-'}</Content>
          )}
        </DetailsRow>
      </Items>
    </Details>
  );
}
