import React, { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { nil, type EntityModels } from 'imddata';

import { Content } from 'imdui';
import AdyenCardGateway from './AdyenCardGateway';
import PaypalGateway from './PaypalGateway';
import type {
  PaymentMethodData,
  PaymentMethod,
  GatewayProps,
  PaymentData,
  OnPaymentChange,
} from './types';

const CreditGateway = ({ onPaymentChange }: GatewayProps) => {
  useEffect(() => {
    onPaymentChange({
      paymentMethod: nil,
      id: nil,
      isReady: true,
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div>
      <Content>{t('credit')} </Content>
    </div>
  );
};

const getPaymentProcessorComponent = (
  paymentMethod: PaymentMethod
): React.FC<GatewayProps> => {
  switch (paymentMethod) {
    case nil:
      return CreditGateway;
    case 'card':
      return AdyenCardGateway;
    case 'paypal':
      return PaypalGateway;
    default: {
      throw new Error('not supported payment gate');
    }
  }
};
export const PaymentProcessor = ({
  paymentData,
  paymentMethod,
  onChange,
  paymentMethodData = {
    data: { originKey: undefined, allowSocialSecurityNumber: false },
  },
  customerPaymentMethods = [],
}: {
  customerPaymentMethods?: Array<EntityModels.CustomerPaymentMethod>;
  paymentData: PaymentData;
  paymentMethodData: PaymentMethodData;
  paymentMethod: PaymentMethod;
  onChange: (data: PaymentData) => void;
}) => {
  const Processor = useMemo(
    () => getPaymentProcessorComponent(paymentMethod),
    [paymentMethod]
  );

  const onPaymentChange = useCallback<OnPaymentChange>(
    (data) => {
      if (data) {
        onChange(data);
      }
    },
    [onChange]
  );

  const {
    fee,
    data: { originKey, allowSocialSecurityNumber, enforceStorePaymentMethod },
  } = paymentMethodData;

  const formData = {
    enforceStorePaymentMethod,
    originKey,
    allowSocialSecurityNumber,
    fee,
  };

  return (
    <Processor
      customerPaymentMethods={customerPaymentMethods}
      paymentData={paymentData}
      formData={formData}
      onPaymentChange={onPaymentChange}
    />
  );
};
