import React, { useMemo } from 'react';
import { Global, css } from '@emotion/react';
import { baseTheme, DARK_MODE_COLORS, LIGHT_MODE_COLORS } from '@imus/base-ui';
import { useSettings } from 'imddata';

const interPreix = '/fonts/Inter/Inter-';

const systemCss = css`
  @media (prefers-color-scheme: light) {
    :root {
      ${LIGHT_MODE_COLORS}
    }
  }
  @media (prefers-color-scheme: dark) {
    :root {
      ${DARK_MODE_COLORS}
    }
  }
`;
const darkCss = css`
  :root {
    ${DARK_MODE_COLORS}
  }
`;
const lightCss = css`
  :root {
    ${LIGHT_MODE_COLORS}
  }
`;

const defaultCss = css`
  :root {
    ${baseTheme}
  }
  svg {
    fill: none;
  }
  body {
    overflow-x: hidden;
    background: var(--background, #e6e5e3);
  }
  html {
    width: 100%;
    background: var(--background, #e6e5e3);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s !important;
  }

  #app {
    display: flex;
    width: 100%;
  }

  a {
    color: var(--accent);
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .link:focus,
  .link:active {
    color: var(--accent);
  }

  h1 {
    margin: 0;
  }

  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }

  .react-tooltip__tooltip_KtSkBq {
    visibility: hidden;
    width: max-content;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 90%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    will-change: opacity, visibility;
  }
  .react-tooltip__fixed_KtSkBq {
    position: fixed;
  }
  .react-tooltip__arrow_KtSkBq {
  }
  .react-tooltip__no-arrow_KtSkBq {
    display: none;
  }
  .react-tooltip__clickable_KtSkBq {
    pointer-events: auto;
  }

  .react-tooltip__show_KtSkBq {
    visibility: visible;
    opacity: 1;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url('${interPreix}Regular.woff2?v=3.19') format('woff2'),
      url('${interPreix}Regular.woff?v=3.19') format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
      url('${interPreix}Italic.woff2?v=3.19') format('woff2'),
      url('${interPreix}Italic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      url('${interPreix}Medium.woff2?v=3.19') format('woff2'),
      url('${interPreix}Medium.woff?v=3.19') format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src:
      url('${interPreix}MediumItalic.woff2?v=3.19') format('woff2'),
      url('${interPreix}MediumItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      url('${interPreix}SemiBold.woff2?v=3.19') format('woff2'),
      url('${interPreix}SemiBold.woff?v=3.19') format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src:
      url('${interPreix}SemiBoldItalic.woff2?v=3.19') format('woff2'),
      url('${interPreix}SemiBoldItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      url('${interPreix}Bold.woff2?v=3.19') format('woff2'),
      url('${interPreix}Bold.woff?v=3.19') format('woff');
  }
`;

export default function WithGlobalStyles() {
  const { settings } = useSettings();
  console.log(settings);

  const theme = settings.applicationTheme;
  const styles = useMemo(() => {
    if (theme === 'system') {
      return [defaultCss, systemCss];
    }
    if (theme === 'dark') {
      return [defaultCss, darkCss];
    }
    if (theme === 'light') {
      return [defaultCss, lightCss];
    }
    return [defaultCss, systemCss];
  }, [theme]);

  return <Global styles={styles} />;
}
